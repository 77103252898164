<div class="cookie-bar border-top p-2" *ngIf="cookiesNotConsent">
  <div class="d-none d-sm-flex justify-content-between align-items-center">
    <div>This website uses necessary cookies to ensure the best user experience. You can disable this by updating your browser settings.</div>
    <div class="d-flex align-items-center">
      <a href="https://www.maplix.com/privacy-policy" target="_blank" class="ml-3 my-0">Learn&nbsp;more</a>
      <button class="btn btn-sm btn-primary ml-3" (click)="onConsentCookies()">Got&nbsp;it!</button>
    </div>
  </div>
  <div class="d-block d-sm-none">
    <div>This website uses necessary cookies to ensure the best user experience. You can disable this by updating your browser settings.</div>
    <p class="m-0 text-center"><a href="https://www.maplix.com/privacy-policy" target="_blank" class="mt-2">Learn&nbsp;more</a></p>
    <button class="w-100 btn btn-sm btn-primary mt-2" (click)="onConsentCookies()">Got&nbsp;it!</button>
  </div>
</div>