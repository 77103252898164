import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import * as Realm from 'realm-web';
import { CookieService } from 'ngx-cookie-service';
import { WorkspaceService } from '@maplix/services';

@Injectable({
  providedIn: 'root',
})
export class RealmService {
  private app: Realm.App;

  constructor(private cookie: CookieService, private workspaceService: WorkspaceService) {
    this.app = new Realm.App({ id: environment.stitchId });
  }

  public auth = {
    checkApiKey: async (token): Promise<void> => {
      const credentials = Realm.Credentials.apiKey(token);
      await this.app.logIn(credentials).catch(() => {
        this.auth.logout();
      });
    },

    logout: () => {
      this.cookie.delete(`userDetails${environment.env}`, '/', environment.cookieDomain);
      this.cookie.delete(`activeWorkspace${environment.env}`, '/', environment.cookieDomain);
      this.cookie.delete(`MaplixToken${environment.env}`, '/', environment.cookieDomain);
      this.cookie.delete(`MaplixTokenId${environment.env}`, '/', environment.cookieDomain);
      this.workspaceService.setUserDetails(undefined);
      this.cookie.set(`redirectAfterLogin${environment.env}`, environment.engage, null, '/', environment.cookieDomain);
      location.href = `${environment.account}auth/login`;
    },
  };
}
