<header class="navbar-sticky bg-white" id="topnav">
  <nav class="navbar navbar-expand-md navbar-light p-0">
    <div class="container">
      <!-- LOGO -->
      <a routerLink="/" class="h-100 py-3 py-md-0">
        <span class="logo-lg d-flex align-items-start h-100">
          <div class="d-flex align-items-center my-auto">
            <img src="assets/images/explore_logo_primary.svg" alt="" height="35" />
            <!-- <div class="h5 app-tag">
              <div class="badge badge-light-primary">Maps</div>
            </div> -->
          </div>
        </span>
      </a>

      <button class="navbar-toggler ml-auto" type="button" (click)="isMenuCollapsed = !isMenuCollapsed">&#9776;</button>

      <div [ngbCollapse]="isMenuCollapsed" class="collapse navbar-collapse">
        <ul class="navbar-nav list-unstyled topnav-menu ml-auto align-items-center">
          <li class="nav-item">
            <a class="nav-link" routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
              >Home</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/themes" routerLinkActive="active">Themes</a>
          </li>
          <!-- <li class="nav-item">
            <a class="nav-link" routerLink="/datasets" routerLinkActive="active">Datasets</a>
          </li> -->
          <!-- <li class="nav-item">
            <div class="nav-link">
              <button class="btn btn-primary" routerLink="maps/new">Create map</button>
            </div>
          </li> -->

          <!-- Notifications -->
          <li
            class="dropdown nav-item notification-list"
            ngbDropdown
            *ngLet="
              notificationsService.notifications
                | filter: { field: 'type', filter: 'EXPORT' }:'reverse' as notifications
            "
            (openChange)="onToggleNotifications($event)"
          >
            <a
              class="nav-link dropdown-toggle mr-0 waves-effect waves-light"
              ngbDropdownToggle
              id="notificationDropdown"
              href="javascript: void(0);"
              role="button"
              aria-haspopup="false"
              aria-expanded="false"
            >
              <i class="fe-bell noti-icon"></i>
              <span
                class="text-white bg-danger rounded-circle noti-icon-badge"
                *ngIf="(notifications | filter: { field: '_fresh', filter: true })?.length as freshNotifications"
                >{{ freshNotifications }}</span
              >
            </a>
            <div
              class="dropdown-menu dropdown-menu-right dropdown-menu-animated dropdown-lg"
              aria-labelledby="notificationDropdown"
              ngbDropdownMenu
            >
              <div class="dropdown-item noti-title">
                <h5 class="m-0 font-weight-normal">Notifications</h5>
              </div>
              <div class="noti-scroll perfect-scroll">
                <ng-container *ngIf="notifications?.length">
                  <div
                    class="notify-item w-100 clickable"
                    *ngFor="let notification of notifications"
                    ngbDropdownItem
                    (click)="onOpenNotification(notification)"
                  >
                    <ng-container *ngIf="notification?.status as status">
                      <div [class]="notification | map: getNotificationClass">
                        <i [class]="notification | map: getNotificationIcon"></i>
                      </div>
                      <div class="notify-details">
                        <div class="notify-title">{{ notification.title }}</div>
                        <ng-container *ngIf="notification.message">
                          <small class="notify-message">{{ notification.message }}</small>
                        </ng-container>
                        <small class="text-muted">{{
                          notification._created | map: notificationsService?.mapNotificationTime
                        }}</small>
                      </div>
                      <div class="notify-clear ml-auto" *ngIf="notification._fresh">
                        <i class="fas fa-circle text-primary font-8 ml-2"></i>
                      </div>
                    </ng-container>
                  </div>
                </ng-container>

                <ng-container *ngIf="!notifications?.length">
                  <div class="d-flex flex-column align-items-center justify-content-center pt-3 pb-4">
                    <i class="remixicon-mail-unread-fill text-light" style="font-size: 80px"></i>
                    <div class="text-muted">You have no notifications.</div>
                  </div>
                </ng-container>
              </div>
              <div
                class="py-2 d-flex justify-content-center clickable"
                ngbDropdownItem
                [routerLink]="['/notifications']"
                *ngIf="notifications?.length"
              >
                <a class="text-uppercase text-primary">View all</a>
              </div>
            </div>
          </li>

          <li class="dropdown nav-item" ngbDropdown *ngIf="userDetails$ | async as userDetails">
            <a
              class="nav-link dropdown-toggle nav-user mr-0 waves-effect waves-light"
              ngbDropdownToggle
              id="profileDropdown"
              href="javascript: void(0);"
              role="button"
              aria-haspopup="false"
              aria-expanded="false"
            >
              <img
                [src]="
                  userDetails?.image
                    ? userDetails?.image
                    : userDetails?.user_profile?.data?.picture
                    ? userDetails?.user_profile?.data?.picture
                    : 'assets/images/profile-empty.png'
                "
                alt=""
                class="img-border"
              />
              <span class="pro-user-name ml-1">
                <i class="mdi mdi-chevron-down"></i>
              </span>
            </a>
            <div
              class="dropdown-menu dropdown-menu-right profile-dropdown"
              aria-labelledby="profileDropdown"
              ngbDropdownMenu
            >
              <a [href]="engageUrl" class="dropdown-item">
                <div class="d-flex align-items-center justify-content-between">
                  <div class="d-flex align-items-center">
                    <div class="avatar-image mr-3">
                      <img src="assets/images/engage_mix.svg" alt="" />
                    </div>
                    <div>
                      <div class="mb-1">
                        <img src="assets/images/engage_logo_primary.svg" height="30" />
                      </div>
                      <small class="text-muted">Start creating your survey</small>
                    </div>
                  </div>
                  <i class="mdi mdi-chevron-right pl-4 font-20 text-muted"></i>
                </div>
              </a>
              <div class="dropdown-divider"></div>
              <a [href]="accountUrl" class="dropdown-item">
                <div class="d-flex align-items-center">
                  <i class="remixicon-user-line mr-2"></i>
                  <span>My account</span>
                </div>
              </a>
              <a href="javascript:void(0);" class="dropdown-item" (click)="logout()">
                <div class="d-flex align-items-center">
                  <i class="remixicon-logout-box-line mr-2"></i>
                  <span>Sign out</span>
                </div>
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>

  <div class="">
    <hr class="bottom my-0 w-100" />
  </div>
</header>
