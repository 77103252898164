<footer class="footer-sticky border-top">
  <div class="container">
    <div class="row">
      <div class="col-12 d-flex">
        <div class="footer-links">
          <a class="d-inline-block">
            <a class="d-flex align-items-center">
              <img src="assets/images/explore_logo_primary.svg" alt="" height="20" class="mr-1" />
              <a href="https://www.maplix.com/" target="_blank"><span class="mr-1">&copy;</span> Powered by Maplix</a>
            </a>
          </a>
          <a href="https://www.maplix.com/terms-conditions" target="_blank"> Terms & Conditions </a>
          <a href="https://www.maplix.com/privacy-policy" target="_blank"> Privacy Policy </a>
        </div>
      </div>
    </div>
  </div>
</footer>
