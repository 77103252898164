export const environment = {
  env: 'PROD',
  cookieDomain: 'maplix.com',
  production: true,
  engage: 'https://engage.maplix.com/',
  account: 'https://account.maplix.com/',
  explore: 'https://explore.maplix.com/',
  api: `https://api.maplix.com/`,
  stitchId: 'maplix-account-prod-ukpqz',
  geoserver: 'https://geo.maplix.com/geoserver/',
  geoserverDb: 'maplix-prod',
  mapboxToken: 'pk.eyJ1IjoiY2VkcmljdmVyc2x1eXMiLCJhIjoiY2s5azFwaTFvMDg5czNsbnNxY2ExbHgyeSJ9.W_Tk-JA9ujNEL55b_cf7Hw',
  plausible: {
    domain: 'explore.maplix.com',
  },
};
