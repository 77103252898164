import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { UiModule } from '@maplix/ui';
import { ToastrModule } from 'ngx-toastr';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { DirectivesModule } from '@maplix/directives';
import { ServicesModule } from '@maplix/services';
import { ApiModule } from '@maplix/api';
import { CacheInterceptor, CleanObjectInterceptor, MaplixApp } from '@maplix/utils';
import { AuthInterceptor } from './shared/interceptors/auth.interceptor';
import { SharedService } from './shared/services/shared.service';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../environments/environment';
import { LayoutsModule } from './layouts/layouts.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    HttpClientModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: 'en',
      useDefaultLang: true,
    }),
    UiModule,
    LayoutsModule,
    DirectivesModule.forRoot(environment),
    ServicesModule.forRoot(environment, MaplixApp.EXPLORE),
    ApiModule.forRoot(environment),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CleanObjectInterceptor,
      multi: true,
    },
    SharedService,
    CookieService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
