import { Component, OnInit } from '@angular/core';
import { ApiService } from '@maplix/api';
import { PlausibleService, WorkspaceService } from '@maplix/services';
import { IUserDetails } from '@maplix/utils';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { RealmService } from './shared/services/realm.service';
import { SharedService } from './shared/services/shared.service';
import { DestroyComponent } from '@maplix/ng-utils';

@Component({
  selector: 'maps-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends DestroyComponent implements OnInit {
  public cookieDomain: string = environment.cookieDomain;

  showMobileMenu = false;

  public engageUrl: string = environment.engage;

  public user$: Observable<IUserDetails> = this.workspaceService.userDetails$;

  constructor(
    private plausible: PlausibleService,
    public shared: SharedService,
    private api: ApiService,
    private cookie: CookieService,
    private workspaceService: WorkspaceService,
    private realmService: RealmService
  ) {
    super();
    this.plausible.initTracker();
  }

  ngOnInit() {
    const activeWorkspaceJSON = this.cookie.get(`activeWorkspace${environment.env}`);
    const activeWorkspace = activeWorkspaceJSON ? JSON.parse(activeWorkspaceJSON) : null;
    if (activeWorkspace) {
      this.workspaceService.setActiveWorkspace(activeWorkspace);
      this.api.organisation
        .getOrganisationById()
        .pipe(takeUntil(this.destroyed))
        .subscribe(
          (response) => {
            this.workspaceService.setActiveWorkspace(response);
            if (response) {
              this.cookie.set(
                `activeWorkspace${environment.env}`,
                JSON.stringify(response),
                30,
                '/',
                environment.cookieDomain
              );
            } else {
              this.realmService.auth.logout();
            }
          },
          () => {
            this.realmService.auth.logout();
          }
        );
    }

    if (this.cookie.get(`MaplixToken${environment.env}`)) {
      this.realmService.auth.checkApiKey(this.cookie.get(`MaplixToken${environment.env}`));
    }

    const userDetailsJSON = this.cookie.get(`userDetails${environment.env}`);
    const userDetails = userDetailsJSON ? JSON.parse(userDetailsJSON) : null;

    if (userDetails) {
      this.api.user
        .getUserById(userDetails._id)
        .pipe(takeUntil(this.destroyed))
        .subscribe((response) => {
          this.workspaceService.setUserDetails(response);
          if (response) {
            this.cookie.set(
              `userDetails${environment.env}`,
              JSON.stringify(response),
              30,
              '/',
              environment.cookieDomain
            );
          }
        });
    }
  }
}
