import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterPipe, FormGroupFilterPipe } from './filter.pipe';
import { FormGroupSortPipe, RandomOrderPipe, SortPipe } from './sort.pipe';
import { MapPipe } from './map.pipe';
import { HasObserversPipe } from './has-observers.pipe';
import { LanguagePlaceholderPipe } from './language-placeholder.pipe';

@NgModule({
  declarations: [FilterPipe, FormGroupFilterPipe, SortPipe, RandomOrderPipe, FormGroupSortPipe, MapPipe, HasObserversPipe, LanguagePlaceholderPipe],
  imports: [CommonModule],
  exports: [FilterPipe, FormGroupFilterPipe, SortPipe, RandomOrderPipe, FormGroupSortPipe, MapPipe, HasObserversPipe, LanguagePlaceholderPipe]
})
export class PipesModule { }
