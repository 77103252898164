import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { WorkspaceService } from '@maplix/services';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private workspaceService: WorkspaceService, private cookie: CookieService) {}

  canActivate(route: ActivatedRouteSnapshot) {
    const environment = route.data.env as any;
    let isAuthenticated: boolean;
    const authJSON = this.cookie.get(`userDetails${environment.env}`);
    const session = authJSON ? JSON.parse(authJSON) : null;
    const activeWorkspaceJSON = this.cookie.get(`activeWorkspace${environment.env}`);
    const activeWorkspace = activeWorkspaceJSON ? JSON.parse(activeWorkspaceJSON) : null;

    if (!session || !activeWorkspace) {
      isAuthenticated = false;
      location.href = `${environment.account}auth/login`;
    } else {
      this.workspaceService.setUserDetails(session);
      this.workspaceService.setActiveWorkspace(activeWorkspace);
      isAuthenticated = true;
    }

    return isAuthenticated;
  }
}
