import { ObjectId } from '../object-id';
import { MaplixApp, MaplixEntity } from './app';

export interface ITheme {
  _id: ObjectId;
  app?: MaplixApp;
  entity?: MaplixEntity;
  organisation?: ObjectId;
  name?: string;
  image?: string;
  colors: IThemeColors;
  options: IThemeOptions;
}

export interface IThemeColors {
  primaryBg: string;
  primaryText: string;
  accent: string;
}

export interface IThemeOptions {
  survey?: IThemeSurveyOptions;
  map?: IThemeMapOptions;
}

export interface IThemeSurveyOptions {
  background: IThemeSurveyBackground;
  questionNumbers: IThemeSurveyQuestionNumbers;
  progressBar: IThemeSurveyProgressBar;
}

export interface IThemeSurveyBackground {
  color: string;
  image: string | null;
  imageRepeat: ImageRepeat | null;
}

export interface IThemeSurveyQuestionNumbers {
  show: boolean;
}

export interface IThemeSurveyProgressBar {
  show: boolean;
}

export enum ImageRepeat {
  COVER = 'COVER',
  REPEAT = 'REPEAT',
}

export interface IThemeMapOptions {
  titleBar: IThemeMapTitleBar;
  legend: IThemeMapLegend;
  addressSearch: IThemeMapAddressSearch;
  baseLayerToggle: IThemeMapBaseLayerToggle;
}

export interface IThemeMapTitleBar {
  show: boolean;
  position?: TitleBarPosition;
}

export enum TitleBarPosition {
  TOP = 'TOP',
  BOTTOM = 'BOTTOM',
}

export interface IThemeMapLegend {
  show: boolean;
  style: LegendStyle;
  collapseByDefault?: boolean;
  position?: LegendPosition;
}

export enum LegendStyle {
  FIXED = 'FIXED',
  ABSOLUTE = 'ABSOLUTE',
  DROPDOWN = 'DROPDOWN',
}

export enum LegendPosition {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
  TOPRIGHT = 'TOPRIGHT',
  BOTTOMRIGHT = 'BOTTOMRIGHT',
}

export interface IThemeMapAddressSearch {
  show: boolean;
}

export interface IThemeMapBaseLayerToggle {
  show: boolean;
}
