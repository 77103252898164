import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ILanguage, ToastrNotification } from '@maplix/utils';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  private isoLanguages: BehaviorSubject<ILanguage[]> = new BehaviorSubject([]);
  public isoLanguages$: Observable<ILanguage[]> = this.isoLanguages.asObservable();

  public languages: ILanguage[];

  public selectedLanguage: ILanguage;
  public surveyLanguageChange: Subject<void> = new Subject();

  public notification: ToastrNotification;

  constructor(
    public http: HttpClient,
    toastr: ToastrService,
    public translate: TranslateService,
    public domSanitizer: DomSanitizer
  ) {
    this.http.get('assets/availablelanguages.json').subscribe((response: any) => {
      this.isoLanguages.next(
        response.map((lang: any) => {
          return {
            code: lang.code,
            name: lang.name,
            nativeName: lang.nativeName,
            display: lang.code !== 'en' ? `${lang.nativeName} (${lang.name})` : lang.name,
          };
        })
      );
    });

    this.notification = new ToastrNotification(toastr);
  }

  public getLanguages(): ILanguage[] {
    return this.isoLanguages.getValue();
  }
}
