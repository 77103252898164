import { Pipe, PipeTransform } from '@angular/core';
import { FormGroup } from '@maplix/forms';

@Pipe({
  name: 'formGroupFilter',
  pure: false,
})
export class FormGroupFilterPipe implements PipeTransform {
  transform(items: FormGroup<any>[], filterObj): any {
    if (!items || !filterObj) {
      return items;
    }

    if (filterObj.exists !== undefined) {
      if (filterObj.exists) {
        return items.filter((item) => item.get(filterObj.field) && item.get(filterObj.field).value);
      } else {
        return items.filter((item) => !item.get(filterObj.field) || !item.get(filterObj.field).value);
      }
    }

    return items.filter((item) => item.get(filterObj.field).value === filterObj.filter);
  }
}

@Pipe({
  name: 'filter',
  pure: false,
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], filterObj, type?: null | 'reverse'): any {
    if (!items || !filterObj || !Array.isArray(items)) {
      return items;
    }
    if (typeof filterObj !== 'object' && !type) {
      return items.filter((item) => item === filterObj);
    }
    if (typeof filterObj !== 'object' && type === 'reverse') {
      return items.filter((item) => item !== filterObj);
    }
    if (type === 'reverse') {
      return items.filter((item) => item[filterObj.field] !== filterObj.filter);
    }
    if (filterObj.exists !== undefined) {
      if (filterObj.exists) {
        return items.filter((item) => item[filterObj.field]);
      } else {
        return items.filter((item) => !item[filterObj.field]);
      }
    }
    return items.filter((item) => item[filterObj.field] === filterObj.filter);
  }
}
