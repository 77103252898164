import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from '../../../shared/services/shared.service';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../../../environments/environment';
import { NotificationsService, WorkspaceService } from '@maplix/services';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IUserDetails } from '@maplix/utils';

@Component({
  selector: 'maps-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent implements OnInit {
  public isMenuCollapsed = true;

  public userDetails$: Observable<IUserDetails> = this.workspaceService.userDetails$;

  public engageUrl: string = environment.engage;

  public accountUrl: string = environment.account;

  private destroyed: Subject<void> = new Subject();

  constructor(
    private router: Router,
    public shared: SharedService,
    private cookie: CookieService,
    private workspaceService: WorkspaceService,
    public notificationsService: NotificationsService
  ) {}

  ngOnInit() {
    this.notificationsService.loadNotifications().pipe(takeUntil(this.destroyed)).subscribe();
  }

  /**
   * Sign out the user
   */
  logout() {
    this.cookie.delete(`userDetails${environment.env}`, '/', environment.cookieDomain);
    this.cookie.delete(`activeWorkspace${environment.env}`, '/', environment.cookieDomain);
    this.cookie.delete(`MaplixToken${environment.env}`, '/', environment.cookieDomain);
    this.cookie.delete(`MaplixTokenId${environment.env}`, '/', environment.cookieDomain);
    this.workspaceService.setUserDetails(undefined);
    location.href = `${environment.account}auth/login`;
  }

  public onToggleNotifications(open: boolean) {
    if (open) {
      const freshNotifications = this.notificationsService.notifications.filter((notification) => notification._fresh);
      if (freshNotifications && freshNotifications.length) {
        this.notificationsService
          .readNotifications(this.notificationsService.viewId)
          .pipe(takeUntil(this.destroyed))
          .subscribe();
      }
    }
  }

  public onOpenNotification(notification: any) {
    switch (notification.type) {
      case 'EXPORT':
        this.router.navigate(['surveys', notification.surveyId, 'results', 'exports']);
        break;
      case 'ANNOUNCEMENT':
        this.router.navigate(['announcements', notification.announcementId]);
        break;
      default:
        this.router.navigate(['notifications', notification._id]);
        break;
    }
  }

  public getNotificationClass(notification: any): string {
    if (!notification) {
      return;
    }

    let result = 'notify-icon ';
    switch (notification.status) {
      case 'INFO':
        result += 'bg-soft-primary text-primary';
        break;
      case 'SUCCESS':
        result += 'bg-soft-success text-success';
        break;
      case 'FAILED':
      case 'ERROR':
        result += 'bg-soft-danger text-danger';
        break;
      case 'PENDING':
      case 'WARNING':
        result += 'bg-soft-warning text-warning';
        break;
    }

    return result;
  }

  public getNotificationIcon(notification: any): string {
    if (!notification) {
      return;
    }

    if (notification.status === 'PENDING') {
      return 'fas fa-circle-notch fa-spin';
    }

    let result = 'remixicon-information-line';
    switch (notification.type) {
      case 'ANNOUNCEMENT':
        result = 'remixicon-volume-up-line';
        break;
      case 'LAYER':
        result = 'fe-layers';
        break;
      case 'EXPORT':
        result = 'remixicon-download-2-line';
        break;
    }

    return result;
  }
}
