import { HttpClient, HttpEventType, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

export interface UploadOptions {
  file: File;
  maxSize?: number;
  apiUrl: string;
}

export type Dimensions = [number, number];

export interface IUploadResponse {
  url: string;
  dimensions?: Dimensions;
}

export class FileUploadHandler {
  private file: File;

  public uploadProgress: number = 0;

  public uploadError: boolean = false;

  private maxSize: number = 1024 * 1024; // default to 1 MB

  constructor(private options: UploadOptions, private http: HttpClient) {
    this.file = options.file;
    this.maxSize = options.maxSize ? options.maxSize : this.maxSize;
  }

  public upload(): Promise<IUploadResponse> {
    return new Promise((resolve, reject) => {
      const allowed = this.file.size <= this.maxSize;
      if (allowed) {
        this.uploadFile(this.file).subscribe(
          async (event) => {
            if (event.type === HttpEventType.UploadProgress) {
              this.uploadProgress = Math.round((100 * event.loaded) / event.total);
            } else if (event instanceof HttpResponse) {
              const response = event.body;
              const image = `${this.options.apiUrl}${response.file.file}`;

              // Get the data URL of the image as a string
              const fileAsDataURL = window.URL.createObjectURL(this.file);
              const dimensions = await this.getHeightAndWidthFromDataUrl(fileAsDataURL);
              resolve({
                url: image,
                dimensions,
              });
            }
          },
          () => {
            this.uploadError = true;
            this.uploadProgress = 100;
            reject('Something went wrong. Please try again later, or pick a different file.');
          }
        );
      } else {
        reject(
          'File size is too big. Maximum file size is ' +
            (this.maxSize > 1024 * 1024 ? this.maxSize / 1024 / 1024 + 'MB' : this.maxSize / 1024 + 'KB')
        );
      }
    });
  }

  private uploadFile(file: File): Observable<any> {
    const data = new FormData();
    data.append('file', file);
    return this.http.post(`${this.options.apiUrl}uploads`, data, { reportProgress: true, observe: 'events' });
  }

  private getHeightAndWidthFromDataUrl(dataURL): Promise<Dimensions> {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        resolve([img.width, img.height]);
      };
      img.onerror = () => {
        resolve(null);
      };
      img.src = dataURL;
    });
  }
}
