import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbDropdownModule, NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { TopbarComponent } from './components/topbar/topbar.component';
import { FooterComponent } from './components/footer/footer.component';
import { DirectivesModule } from '@maplix/directives';
import { LayoutTopbarComponent } from './layout-topbar/layout-topbar.component';
import { PipesModule } from '@maplix/pipes';

@NgModule({
  declarations: [TopbarComponent, FooterComponent, LayoutTopbarComponent],
  imports: [CommonModule, RouterModule, NgbDropdownModule, NgbCollapseModule, DirectivesModule, PipesModule],
  exports: [TopbarComponent],
})
export class LayoutsModule {}
