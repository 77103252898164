import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CookieConsentComponent } from './cookie-consent/cookie-consent.component';
import { PreloaderComponent } from './preloader/preloader.component';
import { CustomColorPickerComponent } from './custom-color-picker/custom-color-picker.component';
import { MaplixQuillEditorComponent } from './quill-editor/quill-editor.component';
import { SecureImageComponent } from './secure-image/secure-image.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ColorSketchModule } from 'ngx-color/sketch';
import { QuillModule } from 'ngx-quill';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgSelectModule } from '@ng-select/ng-select';
import { ConfirmButtonComponent } from './confirm-button/confirm-button.component';
import Quill from 'quill';
import { SweetAlert2Module, SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { SearchableListComponent } from './searchable-list/searchable-list.component';
import { IconTextButtonComponent } from './icon-text-button/icon-text-button.component';
import { SliderNumberComponent } from './slider-number/slider-number.component';
import { PipesModule } from '@maplix/pipes';
import { QuillViewComponent } from './quill-view/quill-view.component';
import { GridCardComponent } from './grid-card/grid-card.component';
import { GridListToggleComponent } from './grid-list-toggle/grid-list-toggle.component';
import { IconAnchorPickerComponent } from './icon-anchor-picker/icon-anchor-picker.component';
import { SelectorComponent } from './selector/selector.component';
import { StarSelectorComponent } from './star-selector/star-selector.component';
import { ColorDropdownComponent } from './color-dropdown/color-dropdown.component';
import { WidgetIconComponent } from './widget-icon/widget-icon.component';
import { StatWidgetComponent } from './stat-widget/stat-widget.component';
import { TitleWithDividerComponent } from './title-with-divider/title-with-divider.component';
import { PageDividerComponent } from './page-divider/page-divider.component';
import { DoubleSliderComponent } from './double-slider/double-slider.component';
import { GridCardHeaderTemplateDirective } from './grid-card/ng-templates.directive';

const Link = Quill.import('formats/link');
Link.sanitize = (url) => {
  if (!url.includes('mailto:') && !url.includes('http://') && !url.includes('https://')) {
    url = 'http://' + url;
  }
  return url;
};

@NgModule({
  declarations: [
    CookieConsentComponent,
    PreloaderComponent,
    CustomColorPickerComponent,
    MaplixQuillEditorComponent,
    SecureImageComponent,
    ConfirmButtonComponent,
    SearchableListComponent,
    IconTextButtonComponent,
    SliderNumberComponent,
    QuillViewComponent,
    GridCardComponent,
    GridListToggleComponent,
    IconAnchorPickerComponent,
    SelectorComponent,
    StarSelectorComponent,
    ColorDropdownComponent,
    WidgetIconComponent,
    StatWidgetComponent,
    TitleWithDividerComponent,
    PageDividerComponent,
    DoubleSliderComponent,
    GridCardHeaderTemplateDirective,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    ColorSketchModule,
    QuillModule.forRoot(),
    BsDatepickerModule.forRoot(),
    NgSelectModule,
    SweetAlert2Module,
    PipesModule,
  ],
  exports: [
    CookieConsentComponent,
    PreloaderComponent,
    CustomColorPickerComponent,
    MaplixQuillEditorComponent,
    SecureImageComponent,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    ColorSketchModule,
    QuillModule,
    BsDatepickerModule,
    NgSelectModule,
    ConfirmButtonComponent,
    SearchableListComponent,
    IconTextButtonComponent,
    SliderNumberComponent,
    QuillViewComponent,
    GridCardComponent,
    GridListToggleComponent,
    IconAnchorPickerComponent,
    SelectorComponent,
    StarSelectorComponent,
    ColorDropdownComponent,
    WidgetIconComponent,
    StatWidgetComponent,
    TitleWithDividerComponent,
    PageDividerComponent,
    DoubleSliderComponent,
    GridCardHeaderTemplateDirective,
  ],
  providers: [SwalComponent],
})
export class UiModule {}
