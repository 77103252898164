import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WorkspaceService } from '@maplix/services';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  ToastrNotification,
  ISurvey,
  ObjectId,
  MaplixApp,
  ITheme,
  stripeProductMapper,
  IStripeProduct,
  IOrder,
  IMapResult,
} from '@maplix/utils';
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { map } from 'rxjs/operators';

@Injectable()
export class ApiService {
  private notification: ToastrNotification;

  constructor(
    @Inject('environment') private environment: any,
    private http: HttpClient,
    toastr: ToastrService,
    private workspaceService: WorkspaceService,
    private domSanitizer: DomSanitizer
  ) {
    this.notification = new ToastrNotification(toastr);
  }

  public payments = {
    getProducts: (): Observable<IStripeProduct[]> => {
      return this.http
        .get(`${this.environment.api}payments/products`)
        .pipe(map((products: any[]) => products.map((product) => stripeProductMapper(product))));
    },
    checkout: (data): Observable<{ invoice: string; order: string }> => {
      return this.http.post<{ invoice: string; order: string }>(`${this.environment.api}payments/checkout`, data);
    },
    getOrders: (orgId: string, page: number = 1, pageSize: number = 25): Observable<any> => {
      return this.http.get(
        `${this.environment.api}organisations/${orgId}/orders?where={"status": {"$ne": "CANCELLED"}}&max_results=${pageSize}&page=${page}&sort=[("_created", -1)]`
      );
    },
    getOrderById: (orgId: string, invoiceId: string): Observable<IOrder> => {
      return this.http.get<IOrder>(`${this.environment.api}organisations/${orgId}/orders/${invoiceId}`);
    },
    getCredits: (orgId: string, app?: MaplixApp): Observable<any> => {
      let appFilter = '';
      if (app) {
        appFilter = `?where={"app":"${app}"}`;
      }

      return this.http.get(`${this.environment.api}organisations/${orgId}/credits${appFilter}`);
    },
  };

  public general = {
    sendMessage: (data): Observable<void> => {
      return this.http.post<void>(`${this.environment.api}landing/contact`, data);
    },
  };

  public organisation = {
    getOrganisationById: (): Observable<any> => {
      return this.http.get(`${this.environment.api}organisations/${this.workspaceService.getActiveWorkspace()._id}`);
    },
    getOrganisationStats: (): Observable<any> => {
      return this.http.get(
        `${this.environment.api}organisations/${this.workspaceService.getActiveWorkspace()._id}/stats`
      );
    },
    getOrganisationsByName: (name: string): Observable<any> => {
      return this.http.get(`${this.environment.api}organisations?where={"name":"${name}"}&max_results=1`);
    },
    getOrganisationsUriAvailability: (uri: string): Observable<{ available: boolean }> => {
      return this.http.get<{ available: boolean }>(`${this.environment.api}organisations/uri/${uri}`);
    },
    createOrganisation: (org: any): Observable<any> => {
      return this.http.post(`${this.environment.api}organisations`, org);
    },
    getOrganisationsByUserid: (): Observable<any> => {
      return this.http.get(`${this.environment.api}users/me/organisations`);
    },
    updateOrganisation: (orgId: string, org: any): Observable<any> => {
      return this.http.patch(`${this.environment.api}organisations/${orgId}`, org);
    },
    getMembers: (orgId: string): Observable<any> => {
      return this.http.get(`${this.environment.api}organisations/${orgId}/members?embedded={"user":1}`);
    },
    inviteMember: (orgId: string, data: any): Observable<any> => {
      return this.http.post(`${this.environment.api}organisations/${orgId}/invite`, data);
    },
  };

  public user = {
    updateOrganisation: (orgId: string): Observable<any> => {
      return this.http.post(`${this.environment.api}users/me/organisations`, {
        organisation: orgId,
      });
    },
    updateUserDetails: (data: any): Observable<any> => {
      return this.http.patch(`${this.environment.api}users/${this.workspaceService.getUserDetails()._id}`, data);
    },
    getUserById: (userId: string): Observable<any> => {
      return this.http.get(`${this.environment.api}users/${userId}`);
    },
    getRecents: (embed?: string): Observable<any> => {
      const userId = this.workspaceService.getUserDetails()._id;
      const orgId = this.workspaceService.getActiveWorkspace()._id;
      let embeddedString = '';
      if (embed) {
        embeddedString = `&embedded={"${embed}": 1}`;
      }

      return this.http.get(
        `${this.environment.api}users/${userId}/recents?where={"organisation":"${orgId}"}${embeddedString}`
      );
    },
    updateRecents: (data: any, recentId: string): Observable<any> => {
      const userId = this.workspaceService.getUserDetails()._id;

      if (recentId) {
        return this.http.patch(`${this.environment.api}users/${userId}/recents/${recentId}`, data);
      }

      return this.http.post(`${this.environment.api}users/${userId}/recents`, data);
    },
  };

  public surveys = {
    getSurveys: (
      page: number = 1,
      pageSize: number = 25,
      searchTerm?: string,
      extraFilter?: string,
      additionalFields?: string[],
      archived: boolean = false
    ): Observable<any> => {
      let filter = '';
      let archivedString = '';

      if (archived) {
        archivedString = `"_archived": {"$ne": null}`;
      } else {
        archivedString = `"_archived": null`;
      }

      if (searchTerm) {
        filter = `&where={${archivedString}, "general.title.value":{"$regex":".*(?i)${searchTerm}.*"}${
          extraFilter ? ', ' + extraFilter : ''
        }}`;
      } else if (extraFilter) {
        filter = `&where={${archivedString}, ${extraFilter}}`;
      } else {
        filter = `&where={${archivedString}}`;
      }

      let additionalFieldsString = '';
      if (additionalFields) {
        additionalFields.forEach((field) => {
          additionalFieldsString += `, "${field}": 1`;
        });
      }

      const projection = `&projection={"_id": 1, "published": 1, "general.thumbnail": 1, "general.title": 1, "general.defaultLanguage": 1, "general.uri": 1, "_paused": 1, "_archived": 1, "statistics": 1${additionalFieldsString}}`;
      return this.http.get(
        `${this.environment.api}surveys?max_results=${pageSize}&page=${page}&sort=[("published", -1), ("_updated", -1)]${projection}${filter}`
      );
    },
    getSurveyById: (id: ObjectId): Observable<ISurvey> => {
      return this.http.get(`${this.environment.api}surveys/${id}`) as Observable<ISurvey>;
    },
    saveSurvey: (surveyId: ObjectId, survey: ISurvey): Observable<any> => {
      if (!surveyId) {
        return this.http.post(`${this.environment.api}surveys`, survey);
      }

      return this.http.patch(`${this.environment.api}surveys/${surveyId}`, survey);
    },
    deleteSurvey: (surveyId: string): Observable<any> => {
      return this.http.delete(`${this.environment.api}surveys/${surveyId}`);
    },
    getSurveyByUri: (uri: string): Observable<ISurvey> => {
      return this.http.get<ISurvey>(`${this.environment.api}surveys/uri/${uri}`);
    },
    getSurveysUriAvailability: (uri: string, currentSurvey?: string): Observable<{ available: boolean }> => {
      let currentFilter = '';
      if (currentSurvey) {
        currentFilter = `&current=${currentSurvey}`;
      }
      return this.http.get<{ available: boolean }>(
        `${this.environment.api}surveys/uri/${uri}?available=true${currentFilter}`
      );
    },
    getSurveyStats: (surveyId: ObjectId): Observable<any> => {
      return this.http.get(`${this.environment.api}surveys/${surveyId}/stats`);
    },
    sendReview: (surveyId: ObjectId, data: any, reviewid?: ObjectId): Observable<any> => {
      if (reviewid) {
        return this.http.put(`${this.environment.api}surveys/${surveyId}/reviews/${reviewid}`, data);
      } else {
        return this.http.post(`${this.environment.api}surveys/${surveyId}/reviews`, data);
      }
    },
    cloneSurvey: (surveyId: ObjectId): Observable<any> => {
      return this.http.post(`${this.environment.api}surveys/${surveyId}/clone`, {});
    },
    printSurveyUrl: (surveyId: ObjectId, languageCode: string): string => {
      return `${this.environment.api}surveys/${surveyId}/print/${languageCode}`;
    },
    getQRCodes: (surveyId: ObjectId): Observable<any> => {
      return this.http.post(`${this.environment.api}surveys/${surveyId}/qr`, {});
    },
  };

  public surveyResponses = {
    saveSurveyResponse: (surveyId: ObjectId, data: any, responseId: ObjectId): Observable<any> => {
      if (responseId) {
        return this.http.put(`${this.environment.api}surveys/${surveyId}/responses/${responseId}`, data);
      } else {
        return this.http.post(`${this.environment.api}surveys/${surveyId}/responses`, data);
      }
    },
    getSurveyResponses: (
      surveyId: ObjectId,
      page: number = 1,
      pageSize: number = 25,
      searchTerm?: string
    ): Observable<any> => {
      let filter: string = '';
      if (searchTerm) {
        filter = `&where={"_id":"${searchTerm}"}`;
      }
      const url = `${this.environment.api}surveys/${surveyId}/responses/list?max_results=${pageSize}&page=${page}&sort=[("_updated", -1)]${filter}`;
      return this.http.get(url);
    },
    deleteSurveyResponses: (surveyId: ObjectId): Observable<any> => {
      return this.http.delete(`${this.environment.api}surveys/${surveyId}/responses/list`);
    },
    deleteSurveyResponse: (surveyId: ObjectId, responseId: ObjectId): Observable<any> => {
      return this.http.delete(`${this.environment.api}surveys/${surveyId}/responses/list/${responseId}`);
    },
  };

  public analytics = {
    getChartByQuestions: (surveyId: ObjectId, data: any): Observable<any> => {
      const headers = new HttpHeaders().set('No-Clean', 'true');
      return this.http.post(`${this.environment.api}surveys/${surveyId}/responses/analytics`, data, { headers });
    },
    getOtherValues: (surveyId: ObjectId, data: any): Observable<any> => {
      const headers = new HttpHeaders().set('No-Clean', 'true');
      return this.http.post(`${this.environment.api}surveys/${surveyId}/responses/analytics`, data, { headers });
    },
    export: (surveyId: ObjectId, data: any): Observable<any> => {
      return this.http.post(`${this.environment.api}surveys/${surveyId}/responses/export`, data);
    },
    saveChart: (surveyId: ObjectId, data: any): Observable<any> => {
      return this.http.post(`${this.environment.api}surveys/${surveyId}/charts`, data);
    },
    getCharts: (surveyId: ObjectId): Observable<any> => {
      return this.http.get(`${this.environment.api}surveys/${surveyId}/charts`);
    },
    getChartById: (surveyId: ObjectId, chartId: ObjectId): Observable<any> => {
      return this.http.get(`${this.environment.api}surveys/${surveyId}/charts/${chartId}`);
    },
    getExports: (surveyId: ObjectId, page: number = 1, pageSize: number = 25): Observable<any> => {
      return this.http.get(
        `${this.environment.api}surveys/${surveyId}/exports?max_results=${pageSize}&page=${page}&sort=[("_created", -1)]&embedded={"user":1}`
      );
    },
    getUploads: (surveyId: ObjectId, question: string, page: number = 1, pageSize: number = 25): Observable<any> => {
      return this.http.get(
        `${this.environment.api}surveys/${surveyId}/uploads?max_results=${pageSize}&page=${page}&where={"question":"${question}"}`
      );
    },
    getInteractionStats: (surveyId: ObjectId, stat: 'count-total' | 'count-respondent'): Observable<any> => {
      return this.http.get(`${this.environment.api}surveys/${surveyId}/responses/interactions/${stat}`).pipe(
        map((resp: any) => {
          return { mapInteractions: resp.map_interactions, imageInteractions: resp.image_interactions };
        })
      );
    },
    getMobilityStats: (
      surveyId: ObjectId,
      group: 'TOTAL' | 'TRANSPORT_MODE' | 'TRANSPORT_MODE_GROUP',
      filter?: { category?: string; page?: number }
    ): Observable<any> => {
      return this.http.post(`${this.environment.api}surveys/${surveyId}/responses/mobility`, { group, filter });
    },
    getResponseStats: (surveyId: ObjectId, stat: string, aggregation?: string): Observable<any> => {
      const data = aggregation ? { aggregation } : {};
      return this.http.post(`${this.environment.api}surveys/${surveyId}/responses/statistics/${stat}`, data);
    },
  };

  public map = {
    getMaps: (page: number = 1, pageSize: number = 25, searchTerm?: string, extraFilter?: string): Observable<any> => {
      let filter: string = '';
      if (searchTerm) {
        filter = `&where={"title.value":{"$regex":".*(?i)${searchTerm}.*"}${extraFilter ? ', ' + extraFilter : ''}}`;
      } else if (extraFilter) {
        filter = `&where={${extraFilter}}`;
      }

      const projection =
        '&projection={"_id": 1, "published": 1, "thumbnail": 1, "title": 1, "uri": 1, "defaultLanguage": 1, "counters": 1, "_paused": 1}';
      return this.http.get(
        `${this.environment.api}maps?max_results=${pageSize}&page=${page}&sort=[("published", -1), ("_updated", -1)]${projection}${filter}`
      );
    },
    saveMap: (data: any, mapId?: ObjectId): Observable<any> => {
      if (!mapId) {
        return this.http.post(`${this.environment.api}maps`, data);
      } else {
        return this.http.patch(`${this.environment.api}maps/${mapId}`, data);
      }
    },
    getMapById: (mapId: ObjectId): Observable<any> => {
      return this.http.get(`${this.environment.api}/maps/${mapId}`);
    },
    deleteMap: (mapId: ObjectId): Observable<any> => {
      return this.http.delete(`${this.environment.api}maps/${mapId}`);
    },
    getWmsProxy: (url: string): Observable<any> => {
      return this.http.get(`${this.environment.api}wms/${url}`, {
        responseType: 'text',
      });
    },
    getMapByUri: (uri: string): Observable<IMapResult> => {
      return this.http.get<IMapResult>(`${this.environment.api}maps/uri/${uri}`);
    },
    getMapsUriAvailability: (uri: string, currentMap?: string): Observable<{ available: boolean }> => {
      let currentFilter = '';
      if (currentMap) {
        currentFilter = `&current=${currentMap}`;
      }
      return this.http.get<{ available: boolean }>(
        `${this.environment.api}maps/uri/${uri}?available=true${currentFilter}`
      );
    },
    addView: (mapId: ObjectId): Observable<void> => {
      return this.http.post<void>(`${this.environment.api}maps/${mapId}/view`, {});
    },
    getQRCodes: (mapId: ObjectId): Observable<any> => {
      return this.http.post(`${this.environment.api}maps/${mapId}/qr`, {});
    },
    cloneMap: (mapId: ObjectId): Observable<any> => {
      return this.http.post(`${this.environment.api}maps/${mapId}/clone`, {});
    },
    getUploadById: (uploadId: ObjectId): Observable<any> => {
      return this.http.get(`${this.environment.api}layers/${uploadId}`);
    },
  };

  public files = {
    uploadForSurvey: (file: File, surveyId: ObjectId, questionId: string): Observable<any> => {
      const data = new FormData();
      data.append('file', file);
      data.append('survey', surveyId);
      data.append('question', questionId);
      return this.http.post(`${this.environment.api}surveys/${surveyId}/uploads`, data, {
        reportProgress: true,
        observe: 'events',
      });
    },
    upload: (file: File | Blob, isThumbnail: boolean = false): Observable<any> => {
      const data = new FormData();
      data.append('file', file);
      if (isThumbnail) {
        data.append('thumbnail', `${isThumbnail}`);
      }
      return this.http.post(`${this.environment.api}uploads`, data, {
        reportProgress: true,
        observe: 'events',
      });
    },
    getFile: (fileId: string): Observable<any> => {
      return this.http.get(`${this.environment.api}uploads/${fileId}`, {
        responseType: 'blob',
      });
    },
    getFileUrl: (url: string): Observable<SafeUrl> => {
      return this.http
        .get(url, { responseType: 'blob' })
        .pipe(map((e) => this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(e))));
    },
    removeFile: (fileId: string): Observable<any> => {
      return this.http.delete(`${this.environment.api}uploads/${fileId}`);
    },
  };

  public notifications = {
    getAllNotifications: (app?: MaplixApp): Observable<any> => {
      let filter = '';
      if (app === MaplixApp.ENGAGE) {
        filter = `&where={"type": {"$ne": "LAYER"}}`;
      } else if (app === MaplixApp.EXPLORE) {
        filter = `&where={"type": {"$ne": "EXPORT"}}`;
      }
      return this.http.get<any>(
        `${this.environment.api}notifications?where={"$or": [{"organisation": null}, {"organisation": "${
          this.workspaceService.getActiveWorkspace()._id
        }"}]}&sort=[("_created", -1)]&max_results=100${filter}`
      );
    },
  };

  public announcements = {
    getAnnouncements: (page: number = 1, pageSize: number = 25): Observable<any> => {
      return this.http.get(
        `${this.environment.api}announcements?sort=[("_created", -1)]&max_results=${pageSize}&page=${page}`
      );
    },
    getAnnouncementById: (announcementId: ObjectId): Observable<any> => {
      return this.http.get(`${this.environment.api}announcements/${announcementId}`);
    },
    getNotificationByAnnouncementId: (announcementId: ObjectId): Observable<any> => {
      return this.http.get(`${this.environment.api}notifications?where={"announcementId": ${announcementId}}`);
    },
    saveAnnouncement: (data: any, announcementId?: ObjectId): Observable<any> => {
      if (announcementId) {
        return this.http.patch(`${this.environment.api}announcements/${announcementId}`, data);
      }

      const userName = this.workspaceService.getUserDetails().name;
      data.creator = userName;
      return this.http.post(`${this.environment.api}announcements`, data);
    },
    publishAnnouncement: (announcementId: ObjectId): Observable<any> => {
      return this.http.post(`${this.environment.api}announcements/${announcementId}/publish`, {});
    },
  };

  public themes = {
    getThemes: (app: MaplixApp, page: number = 1, pageSize: number = 25, searchTerm?: string): Observable<any> => {
      let filter: string = `&where={"app": "${app}"}`;
      if (searchTerm) {
        filter = `&where={"app": "${app}", "name":{"$regex":".*(?i)${searchTerm}.*"}}`;
      }
      return this.http.get(
        `${this.environment.api}themes?sort=[("_created", -1)]&max_results=${pageSize}&page=${page}${filter}`
      );
    },
    getThemeById: (themeId: ObjectId): Observable<any> => {
      return this.http.get(`${this.environment.api}themes/${themeId}`);
    },
    saveTheme: (data: ITheme, themeId?: ObjectId): Observable<any> => {
      if (themeId) {
        return this.http.patch(`${this.environment.api}themes/${themeId}`, data);
      }

      return this.http.post(`${this.environment.api}themes`, data);
    },
    deleteTheme: (themeId: ObjectId): Observable<void> => {
      return this.http.delete<void>(`${this.environment.api}themes/${themeId}`);
    },
  };

  public superuser = {
    getOrganisations: (page: number = 1, pageSize: number = 25, searchTerm?: string): Observable<any> => {
      let filter: string = '';
      if (searchTerm) {
        filter = `&where={"name":{"$regex":".*(?i)${searchTerm}.*"}}`;
      }
      return this.http.get(
        `${this.environment.api}superuser/organisations?sort=[("_created", -1)]&max_results=${pageSize}&page=${page}${filter}`
      );
    },
    getOrganisationById: (id: ObjectId): Observable<any> => {
      return this.http.get(`${this.environment.api}superuser/organisations/${id}`);
    },
    getOrganisationStats: (id: ObjectId): Observable<any> => {
      return this.http.get(`${this.environment.api}organisations/${id}/stats`);
    },
    getMembers: (orgId: string): Observable<any> => {
      return this.http.get(`${this.environment.api}superuser/organisations/${orgId}/members?embedded={"user":1}`);
    },
    addCredits: (orgId: string, data: any): Observable<any> => {
      return this.http.post(`${this.environment.api}superuser/organisations/${orgId}/credits`, data);
    },
    getCredits: (orgId: string): Observable<any> => {
      return this.http.get(`${this.environment.api}superuser/organisations/${orgId}/credits?sort=[("purchased", -1)]`);
    },
  };

  public errorhandler(error) {
    if (error.customMessage) {
      this.notification.danger(error.customMessage, error.status);
    } else if (error.status == 401) {
      this.notification.danger("You don't have the rights to perform this operation", error.status);
    } else if (error.status == 403) {
      this.notification.danger("You don't have the rights to perform this operation", error.status);
    } else if (error.status == 404) {
      this.notification.danger('Data was not found', error.status);
    } else {
      this.notification.danger('Something went wrong', error.status);
      console.error(error);
    }
  }
}
