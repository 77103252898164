import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from '@maplix/guards';
import { environment } from '../environments/environment';
import { LayoutTopbarComponent } from './layouts/layout-topbar/layout-topbar.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutTopbarComponent,
    loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
    canActivate: [AuthGuard],
    data: { env: environment },
  },
  {
    path: 'maps',
    loadChildren: () => import('./map-editor/map-editor.module').then((m) => m.MapEditorModule),
    canActivate: [AuthGuard],
    data: { env: environment },
  },
  {
    path: 'error',
    loadChildren: () => import('@maplix/error').then((m) => m.ErrorModule),
  },
  {
    path: ':lang',
    loadChildren: () => import('./map-viewer/map-viewer.module').then((m) => m.MapViewerModule),
  },
  { path: '**', redirectTo: 'error' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
