import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'maplix-cookie-consent',
  templateUrl: './cookie-consent.component.html',
  styleUrls: ['./cookie-consent.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CookieConsentComponent implements OnInit {

  @Input()
  private cookieDomain: string;

  cookiesNotConsent: boolean;

  constructor(private cookie: CookieService) { }

  ngOnInit() {
    const consent = this.cookie.get('maplixCookieConsent');
    this.cookiesNotConsent = !consent;
  }

  onConsentCookies() {
    this.cookie.set('maplixCookieConsent', "true", 365, '/', this.cookieDomain);
    this.cookiesNotConsent = false;
  }

}
