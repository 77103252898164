import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { WorkspaceService } from '@maplix/services';

@Injectable({ providedIn: 'root' })
export class SuperuserGuard implements CanActivate {
  constructor(private workspaceService: WorkspaceService, private cookie: CookieService) {}

  canActivate(route: ActivatedRouteSnapshot) {
    const environment = route.data.env as any;
    let isSuperuser: boolean;
    const authJSON = this.cookie.get(`userDetails${environment.env}`);
    const session = authJSON ? JSON.parse(authJSON) : null;

    if (!session) {
      isSuperuser = false;
      location.href = `${environment.account}auth/login`;
    } else if (!session.roles || !(Array.isArray(session.roles) && session.roles.includes('SUPERUSER'))) {
      isSuperuser = false;
      location.href = `${environment.account}`;
    } else {
      this.workspaceService.setUserDetails(session);
      isSuperuser = true;
    }

    return isSuperuser;
  }
}
