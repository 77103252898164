export const THEME_COLORS = {
  GENERIC: {
    PRIMARY: '#15BEC5',
    SECONDARY: '#FFA62B',
    FONT: '#304351',
  },
  ACCOUNT: {
    PRIMARY: '#15BEC5',
    SECONDARY: '#FFA62B',
  },
  ENGAGE: {
    PRIMARY: '#00D6A4',
    SECONDARY: '#FFA62B',
  },
  EXPLORE: {
    PRIMARY: '#5465FF',
    SECONDARY: '#FFA62B',
  },
  STRAVA: {
    PRIMARY: '#FC4C01',
  },
};
