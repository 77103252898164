import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { WorkspaceService } from '@maplix/services';

@Injectable({ providedIn: 'root' })
export class ReverseAuthGuard implements CanActivate {
  constructor(private router: Router, private workspaceService: WorkspaceService, private cookie: CookieService) {}

  canActivate(route: ActivatedRouteSnapshot) {
    const environment = route.data.env as any;
    let isLoggedOut: boolean;
    const authJSON = this.cookie.get(`userDetails${environment.env}`);
    const session = authJSON ? JSON.parse(authJSON) : null;
    const activeWorkspaceJSON = this.cookie.get(`activeWorkspace${environment.env}`);
    const activeWorkspace = activeWorkspaceJSON ? JSON.parse(activeWorkspaceJSON) : null;

    if (!session) {
      isLoggedOut = true;
      this.cookie.delete(`MaplixToken${environment.env}`, '/', environment.cookieDomain);
      this.cookie.delete(`MaplixTokenId${environment.env}`, '/', environment.cookieDomain);
      this.cookie.delete(`userDetails${environment.env}`, '/', environment.cookieDomain);
      this.cookie.delete(`activeWorkspace${environment.env}`, '/', environment.cookieDomain);
    } else if (!activeWorkspace) {
      isLoggedOut = true;
      this.cookie.delete(`MaplixToken${environment.env}`, '/', environment.cookieDomain);
      this.cookie.delete(`MaplixTokenId${environment.env}`, '/', environment.cookieDomain);
    } else {
      this.workspaceService.setUserDetails(session);
      isLoggedOut = false;
      this.router.navigate(['/']);
    }

    return isLoggedOut;
  }
}
